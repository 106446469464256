<template>
  <div class="login-form login-signin w-100">
    <form class="form" novalidate="novalidate" id="kt_login_signin_form" @submit.stop.prevent="onSubmitLogin()">
      <div class="pb-13 pt-lg-0 pt-5" v-if="showHeader">
        <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg" v-if="false">{{ $t("AUTH.WELCOME_TO") }} {{ $t("GENERAL.APP_NAME") }}</h3>
      </div>
      <div class="form-group">
        <label class="font-size-h6 font-weight-bolder text-dark">{{ $t("GENERAL.EMAIL") }}</label>

        <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="text" name="email" ref="email" v-model="form.email" />
      </div>
      <div class="form-group">
        <div class="d-flex justify-content-between mt-n5">
          <label class="font-size-h6 font-weight-bolder text-dark pt-5"> {{ $t("GENERAL.PASSWORD") }}</label>
          <a
            class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5 cursor-pointer"
            id="kt_login_forgot"
            @click="$emit('auth-show-form', 'forgot')"
          >
            {{ $t("AUTH.FORGOT_PASSWORD") }}</a
          >
        </div>

        <div class="input-group input-group-lg">
          <input
            class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
            :type="passwordType"
            name="password"
            ref="password"
            v-model="form.password"
            autocomplete="off"
          />
          <button type="button" class="btn btn-outline-primary font-weight-bolder ml-4 px-3 py-2 my-4 font-size-lg" @click="changePasswordType">
            <i class="fa fa-eye" v-if="passwordType === 'password'"></i>
            <i class="fa fa-eye-slash" v-else></i>
          </button>
        </div>
      </div>
      <div class="pb-lg-0 pb-5 d-flex justify-content-end">
        <button ref="kt_login_signin_submit" class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 ml-3">
          {{ $t("GENERAL.SIGNIN") }}
        </button>
        <button v-if="false" type="button" class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg">
          <span class="svg-icon svg-icon-md"> <inline-svg src="media/svg/social-icons/google.svg" /> </span>Google {{ $t("GENERAL.SIGNIN") }}
        </button>
      </div>
      <div class="pb-13 pt-lg-0 pt-5 mt-10" v-if="showHeader">
        <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg" v-if="false">{{ $t("AUTH.WELCOME_TO") }} {{ $t("GENERAL.APP_NAME") }}</h3>

        <div class="d-flex justify-content-between">
          <div class="font-weight-bold font-size-h4 mt-6">
            {{ $t("AUTH.NEW_HERE") }}
          </div>
          <a id="kt_login_signup" class="btn btn-outline-success font-weight-bolder px-6 py-4 my-3 font-size-lg" @click="$emit('auth-show-form', 'signup')">{{
            $t("AUTH.SIGN_UP_HERE")
          }}</a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
  name: "AuthSigninForm",
  props: {
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      passwordType: "password",
    };
  },
  methods: {
    onSubmitLogin() {
      this.signin_form_validation.validate();
    },
    changePasswordType() {
      this.passwordType = this.passwordType === "password" ? "text" : "password";
    },
  },
  computed: {
    ...mapGetters({ layoutConfig: "layoutConfig", currentUser: "auth/currentUser" }),
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");

    let self = this;
    this.signin_form_validation = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              // eslint-disable-next-line prettier/prettier
              message: self.$t("VALIDATION.REQUIRED", {field: self.$t("GENERAL.EMAIL")})
            },
          },
          password: {
            validators: {
              notEmpty: {
                // eslint-disable-next-line prettier/prettier
                message: self.$t("VALIDATION.REQUIRED", {field: self.$t("GENERAL.PASSWORD")})
              },
            },
          },
        },
        plugins: {
          trigger: new Trigger(),
          submitButton: new SubmitButton(),
          bootstrap: new Bootstrap(),
        },
      },
    });

    this.signin_form_validation.on("core.form.valid", () => {
      let username = this.form.email;
      let password = this.form.password;
      let self = this;
      // clear existing errors
      this.$store.dispatch("auth/" + LOGOUT, false);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay

      // send login request
      this.$store
        .dispatch("auth/" + LOGIN, { username, password })
        // go to which page after successfully login
        .then((data) => {
          submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
          self.$emit("logged-in", { user: data, password });
        })
        .catch(() => {
          submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
        });
    });

    this.signin_form_validation.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: this.$t("VALIDATION.WRONG_DATA"),
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
      });
    });
  },
};
</script>
