<template>
  <div
    class="d-flex flex-column-fluid AuthForms login login-1 mt-14"
    :class="{
      'login-signin-on': this.state === 'signin',
      'login-signup-on': this.state === 'signup',
      'login-forgot-on': this.state === 'forgot',
      'login-reset-on': this.state === 'reset',
    }"
    id="kt_login"
  >
    <!--begin::Signin-->
    <auth-signin-form @auth-show-form="showForm" :show-header="showHeader" @logged-in="loggedIn"></auth-signin-form>
    <!--end::Signin-->
    <!--begin::Signup-->
    <auth-signup-form @auth-action-cancel="showForm('signin')"></auth-signup-form>
    <!--end::Signup-->
    <!--begin::Forgot-->
    <auth-forgot-form @auth-action-cancel="showForm('signin')"></auth-forgot-form>
    <!--end::Forgot-->
    <auth-reset-form @password-reseted="goToDashboard" :temp-password="oldPassword"></auth-reset-form>
  </div>
</template>
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import i18nService from "@/core/services/i18n.service";
import KTUtil from "@/assets/js/components/util";
import { mapGetters } from "vuex";
import AuthSigninForm from "@/view/pages/auth/components/AuthSigninForm/AuthSigninForm";
import AuthSignupForm from "@/view/pages/auth/components/AuthSignupForm/AuthSignupForm";
import AuthForgotForm from "@/view/pages/auth/components/AuthForgotForm/AuthForgotForm";
import AuthResetForm from "@/view/pages/auth/components/AuthResetForm/AuthResetForm";

export default {
  name: "auth-forms",
  components: {
    AuthResetForm,
    AuthForgotForm,
    AuthSignupForm,
    AuthSigninForm,
  },

  props: {
    initialState: {
      type: String,
      default: "signin",
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    afterLogin: {
      type: String,
      default: "dashboard",
    },
  },
  data() {
    return {
      oldPassword: "",
      state: "",
    };
  },
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser" }),
  },
  mounted() {
    this.showForm(this.initialState);
  },
  methods: {
    loggedIn({ user, password }) {
      if (user.force_reset_password) {
        this.oldPassword = password;
        this.showForm("reset");
      } else {
        this.oldPassword = "";
        this.goToDashboard();
      }
    },

    goToDashboard() {
      this.$router.push({ name: this.afterLogin }).then(() => {
        i18nService.setActiveLanguageAndReloadIfNeeds(this.currentUser.preferred_language);
      });
    },

    showForm(form) {
      this.state = form;
      let form_name = "kt_login_" + form + "_form";
      let f = KTUtil.getById(form_name);
      //
      KTUtil.animateClass(KTUtil.getById(f), "animate__animated animate__backInUp");
    },
  },
};
</script>
