<template>
  <div class="login-form login-forgot">
    <!--begin::Form-->
    <form class="form" novalidate="novalidate" id="kt_login_forgot_form">
      <div class="pb-13 pt-lg-0 pt-5">
        <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
          {{ $t("AUTH.FORGOT_PASSWORD") }}
        </h3>
        <p class="text-muted font-weight-bold font-size-h4">
          {{ $t("AUTH.FORGOT_PASSWORD_MESSAGE") }}
        </p>
      </div>
      <div class="form-group">
        <label for="f_email"></label>
        <input
          class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
          type="email"
          :placeholder="$t('GENERAL.EMAIL')"
          id="f_email"
          name="f_email"
          ref="f_email"
          autocomplete="off"
        />
      </div>
      <div class="form-group d-flex flex-wrap pb-lg-0 justify-content-end">
        <button
          type="button"
          id="kt_login_forgot_cancel"
          class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
          @click="$emit('auth-action-cancel')"
        >
          {{ $t("GENERAL.CANCEL") }}
        </button>
        <button type="button" ref="kt_login_forgot_submit" @click="onSubmitForgot()" class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3">
          {{ $t("GENERAL.SEND") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import { LOGOUT, RESET_PASSWORD } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import TheToaster from "@/core/services/the-toaster";

export default {
  name: "AuthForgotForm",
  methods: {
    onSubmitForgot() {
      this.forgot_form_validation.validate();
    },
  },
  mounted() {
    const forgot_form = KTUtil.getById("kt_login_forgot_form");
    let self = this;
    this.forgot_form_validation = formValidation(forgot_form, {
      fields: {
        f_email: {
          validators: {
            notEmpty: {
              // eslint-disable-next-line prettier/prettier
              message: self.$t("VALIDATION.REQUIRED", {field: self.$t("GENERAL.EMAIL")})
            },
            emailAddress: {
              message: self.$t("VALIDATION.VALID_EMAIL"),
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.forgot_form_validation.on("core.form.valid", () => {
      const email = this.$refs.f_email.value;

      // clear existing errors
      this.$store.dispatch("auth/" + LOGOUT, false);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_forgot_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // send login request
      this.$store
        .dispatch("auth/" + RESET_PASSWORD, { email })
        .then(() => {
          TheToaster.success("GENERAL.MAIL_WITH_NEW_PASSWORD");
          this.$emit("auth-action-cancel");

          submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
        })
        .catch(() => {
          submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
        });
    });

    this.forgot_form_validation.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: this.$t("VALIDATION.WRONG_DATA"),
        icon: "error",
        confirmBu: "btn btn-secondary",
        heightAuto: false,
      });
    });
  },
};
</script>
