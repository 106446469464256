<template>
  <div class="login-form login-signup">
    <form class="form" novalidate="novalidate" id="kt_login_signup_form">
      <div class="pb-13 pt-lg-0 pt-5">
        <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
          {{ $t("AUTH.SIGN_UP") }}
        </h3>
      </div>
      <div class="form-group">
        <label for="r_fullname"></label>
        <input
          class="form-control form-control-solid h-auto py-4 px-4 rounded-lg font-size-h6"
          type="text"
          :placeholder="$t('GENERAL.NAME')"
          name="r_fullname"
          id="r_fullname"
          ref="r_fullname"
          autocomplete="off"
        />
      </div>
      <div class="form-group">
        <label for="r_email"></label>
        <input
          class="form-control form-control-solid h-auto py-4 px-4 rounded-lg font-size-h6"
          type="email"
          :placeholder="$t('GENERAL.EMAIL')"
          name="r_email"
          id="r_email"
          ref="r_email"
          autocomplete="off"
        />
      </div>
      <div class="form-group">
        <label for="r_password"></label>
        <div class="input-group input-group-lg">
          <input
            class="form-control form-control-solid h-auto py-4 px-4 rounded-lg font-size-h6"
            :type="passwordType1"
            :placeholder="$t('GENERAL.PASSWORD')"
            name="r_password"
            id="r_password"
            ref="r_password"
            autocomplete="off"
          />
          <button type="button" class="btn btn-outline-primary font-weight-bolder ml-4 px-2 py-2 my-3 font-size-lg" @click="changePasswordType(1)">
            <i class="fa fa-eye" v-if="passwordType1 === 'password'"></i>
            <i class="fa fa-eye-slash" v-else></i>
          </button>
        </div>
      </div>
      <div class="form-group">
        <label for="r_c_password"></label>
        <div class="input-group input-group-lg">
          <input
            class="form-control form-control-solid h-auto py-4 px-4 rounded-lg font-size-h6"
            :type="passwordType2"
            :placeholder="$t('GENERAL.CONFIRM_PASSWORD')"
            name="r_c_password"
            id="r_c_password"
            ref="r_c_password"
            autocomplete="off"
          />
          <button type="button" class="btn btn-outline-primary font-weight-bolder ml-4 px-2 py-2 my-3 font-size-lg" @click="changePasswordType(2)">
            <i class="fa fa-eye" v-if="passwordType2 === 'password'"></i>
            <i class="fa fa-eye-slash" v-else></i>
          </button>
        </div>
      </div>
      <div class="form-group">
        <label class="checkbox mb-0" style="font-size: 16px">
          <input type="checkbox" name="r_agree" id="r_agree" ref="r_agree" />
          <span class="mr-2"></span>
          {{ $t("AUTH.I_AGREE_EULA") }}:
          <router-link :to="{ name: 'general-eula' }" class="ml-2">
            {{ $t("AUTH.TERMS_EULA") }}
          </router-link>
        </label>
      </div>
      <div class="form-group d-flex flex-wrap pb-lg-0 pb-3 justify-content-end">
        <button
          type="button"
          id="kt_login_signup_cancel"
          class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
          @click="$emit('auth-action-cancel')"
        >
          {{ $t("GENERAL.CANCEL") }}
        </button>
        <button
          ref="kt_login_signup_submit"
          class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
          style="width: 150px"
          @click.stop.prevent="onSubmitRegister()"
        >
          {{ $t("GENERAL.SEND") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import { REGISTER_USER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import TheToaster from "@/core/services/the-toaster";
import i18nService from "@/core/services/i18n.service";

export default {
  name: "AuthSignupForm",
  data() {
    return {
      signup_form_validation: null,
      passwordType1: "password",
      passwordType2: "password",
    };
  },
  methods: {
    onSubmitRegister() {
      this.signup_form_validation.validate();
    },
    clearAll() {
      this.signup_form_validation.resetForm();
      this.$refs.r_email.value = "";
      this.$refs.r_fullname.value = "";
      this.$refs.r_password.value = "";
      this.$refs.r_c_password.value = "";
      this.$refs.r_agree.value = 0;
    },
    changePasswordType(n) {
      this["passwordType" + n] = this["passwordType" + n] === "password" ? "text" : "password";
    },
  },
  mounted() {
    let self = this;
    const signup_form = KTUtil.getById("kt_login_signup_form");

    this.signup_form_validation = formValidation(signup_form, {
      fields: {
        r_fullname: {
          validators: {
            notEmpty: {
              // eslint-disable-next-line prettier/prettier
              message: self.$t("VALIDATION.REQUIRED", { field: self.$t("GENERAL.NAME")})
            },
          },
        },
        r_email: {
          validators: {
            notEmpty: {
              // eslint-disable-next-line prettier/prettier
              message: self.$t("VALIDATION.REQUIRED", { field: self.$t("GENERAL.EMAIL") })
            },
            emailAddress: {
              message: self.$t("VALIDATION.VALID_EMAIL"),
            },
          },
        },
        r_password: {
          validators: {
            notEmpty: {
              // eslint-disable-next-line prettier/prettier
              message: self.$t("VALIDATION.REQUIRED", { field: self.$t("GENERAL.PASSWORD")})
            },
          },
        },
        r_c_password: {
          validators: {
            notEmpty: {
              // eslint-disable-next-line prettier/prettier
              message: self.$t("VALIDATION.REQUIRED", { field: self.$t("GENERAL.CONFIRM_PASSWORD")})
            },
            identical: {
              compare: function () {
                return signup_form.querySelector('[name="r_password"]').value;
              },
              // eslint-disable-next-line prettier/prettier
              message: self.$t("VALIDATION.CONFIRM_PASSWORD_MATCH")
            },
          },
        },
        r_agree: {
          validators: {
            notEmpty: {
              // eslint-disable-next-line prettier/prettier
              message: self.$t("VALIDATION.AGREE_EULA_TERMS")
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.signup_form_validation.on("core.form.valid", () => {
      const email = this.$refs.r_email.value;
      const name = this.$refs.r_fullname.value;
      const password = this.$refs.r_password.value;
      const preferred_language = i18nService.getActiveLanguage();
      // const password = this.$refs.r_password.value;

      // clear existing errors

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      // send login request
      this.$store
        .dispatch("auth/" + REGISTER_USER, {
          email,
          name,
          password,
          preferred_language,
        })
        .then(() => {
          try {
            fbq("track", "CompleteRegistration");
          } catch (e) {}
          TheToaster.success("GENERAL.SUCCESS_ON_REGISTER");
          this.$emit("auth-action-cancel");
          this.clearAll();
          submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
        })
        .catch(() => {
          submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
        });
    });

    this.signup_form_validation.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: this.$t("VALIDATION.WRONG_DATA"),
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
      });
    });

    setTimeout(() => {
      this.clearAll();
    }, 1000);
  },
};
</script>
