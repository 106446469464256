<template>
  <div class="AuthResetForm login-form login-reset">
    <form class="form" novalidate="novalidate" id="kt_login_reset_form">
      <div class="pb-13 pt-lg-0 pt-5">
        <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
          {{ $t("AUTH.NEW_PASSWORD") }}
        </h3>
      </div>

      <div class="form-group">
        <label for="reset_password"></label>
        <input
          class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
          type="password"
          :placeholder="$tc('GENERAL.PASSWORD', 1)"
          name="reset_password"
          id="reset_password"
          ref="reset_password"
          autocomplete="off"
        />
      </div>
      <div class="form-group">
        <label for="c_reset_password"></label>
        <input
          class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
          type="password"
          :placeholder="$t('GENERAL.CONFIRM_PASSWORD')"
          name="c_reset_password"
          id="c_reset_password"
          ref="c_reset_password"
          autocomplete="off"
        />
      </div>

      <div class="form-group d-flex flex-wrap pb-lg-0 pb-3 justify-content-end">
        <button
          ref="kt_login_reset_submit"
          class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
          style="width: 150px"
          @click.stop.prevent="onSubmitRegister()"
        >
          {{ $t("GENERAL.SEND") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import { UPDATE_USER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import TheToaster from "@/core/services/the-toaster";

export default {
  name: "auth-reset-form",
  props: {
    tempPassword: String,
  },
  data() {
    return {
      reset_form_validation: null,
    };
  },
  methods: {
    onSubmitRegister() {
      this.reset_form_validation.validate();
    },
    clearAll() {
      this.reset_form_validation.resetForm();
      this.$refs.reset_password.value = "";
      this.$refs.c_reset_password.value = "";
    },
  },
  mounted() {
    let self = this;
    const reset_form = KTUtil.getById("kt_login_reset_form");

    this.reset_form_validation = formValidation(reset_form, {
      fields: {
        reset_password: {
          validators: {
            notEmpty: {
              // eslint-disable-next-line prettier/prettier
              message: self.$t("VALIDATION.REQUIRED", { field: self.$t("GENERAL.PASSWORD")})
            },
          },
        },
        c_reset_password: {
          validators: {
            notEmpty: {
              // eslint-disable-next-line prettier/prettier
              message: self.$t("VALIDATION.REQUIRED", { field: self.$t("GENERAL.CONFIRM_PASSWORD")})
            },
            identical: {
              compare: function () {
                return reset_form.querySelector('[name="reset_password"]').value;
              },
              // eslint-disable-next-line prettier/prettier
              message: self.$t("VALIDATION.CONFIRM_PASSWORD_MATCH")
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.reset_form_validation.on("core.form.valid", () => {
      const password = this.$refs.reset_password.value;
      // const password = this.$refs.r_password.value;

      // clear existing errors

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_reset_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      // send login request

      this.$store
        .dispatch("auth/" + UPDATE_USER, {
          payload: {
            current_password: this.tempPassword,
            new_password: password,
            verify_password: password,
          },
          suffix: "/password",
        })
        .then(() => {
          setTimeout(() => {
            TheToaster.success("GENERAL.DATA_SAVED");
            self.clearAll();
            submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
            self.$emit("password-reseted");
          }, 500);
        })
        .finally(() => {
          submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
        });
    });

    this.reset_form_validation.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: this.$t("VALIDATION.WRONG_DATA"),
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
      });
    });

    setTimeout(() => {
      this.clearAll();
    }, 1000);
  },
};
</script>
